<template>
  <div id="page-fixed-bearings">
    <div class="container">
      <AppRouterTabNav :items="tabsItems" />
      <div class="contentbox">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import AppRouterTabNav from '@/components/app/AppRouterTabNav.vue';

export default {
  components: {
    AppRouterTabNav,
  },
  data() {
    return {
      tabsItems: [
        {
          label: this.$i18n.t('tabs.tabEccentricity'),
          link: '/tools/exzentritaet',
        },
        {
          label: this.$i18n.t('tabs.tabEdgeDistanceDIN'),
          link: '/tools/randabstand-din',
        },
        {
          label: this.$i18n.t('tabs.tabEdgeDistanceEC'),
          link: '/tools/randabstand-ec',
        },
        {
          label: this.$i18n.t('tabs.tabEdgeContact'),
          link: '/tools/kantenkontakt',
        },
        {
          label: this.$i18n.t('tabs.tabCrossTraction'),
          link: '/tools/querzugkraft',
        },
        {
          label: this.$i18n.t('tabs.tabHoles'),
          link: '/tools/bohrung',
        },
        {
          label: this.$i18n.t('tabs.tabTwisting'),
          link: '/tools/auflagerverdrehung',
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">

</style>
