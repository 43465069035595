<template>
  <nav
    :class="{ 'is-open': dropdownIsOpen }"
    class="router-tabs"
  >
    <a
      href="#"
      class="router-tabs__dropdown-handler d-flex d-xl-none"
      @click="dropdownHandler()"
    >
      <span class="mr-auto">{{ selectedLabel }}</span>
      <font-awesome-icon icon="chevron-down" />
    </a>
    <ul>
      <li
        v-for="(item, i) in items"
        :key="`AppRouterTabNav${i}`"
      >
        <router-link
          :to="`${item.link}`"
          @click.native="itemHandler(item.label)"
        >
          {{ item.label }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'AppRouterTabNav',
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectedLabel: '',
      dropdownIsOpen: false,
    };
  },
  mounted() {
    this.items.forEach((item) => {
      if (item.link === this.$route.path) {
        this.selectedLabel = item.label;
      }
    });
  },
  methods: {
    dropdownHandler() {
      this.dropdownIsOpen = this.dropdownIsOpen !== true;
    },
    itemHandler(label) {
      this.dropdownIsOpen = false;
      this.selectedLabel = label;
    },
  },
};
</script>

<style scoped lang="scss">
  .router-tabs {
    background:$gray-200;

    &.is-open {
      ul {
        display: block;
      }

      svg {
        transform: rotate(180deg);
      }
    }

    ul {
      display: none;
      list-style-type: none;
      padding:0;
      margin:0;
      li {
        display: block;
        a {
          font-weight: bold;
          padding:15px;
          display: block;
          color:$gray-500;
        }

        .router-link-exact-active {
          background:#fff;
          color:$primary;
        }
      }
    }
  }

  .router-tabs__dropdown-handler {
    padding:15px;
    background:$gray-200;
    border-top:3px solid $primary;
    color:$primary;
    font-weight: bold;
    svg {
      transition: 500ms all ease-in;
      margin:5px 0 0 0;
    }
  }

  @media (min-width: 1200px) {
    .router-tabs {
      ul {
        display: block;
        li {
          display: inline-block;

          .router-link-exact-active {
            border-top:3px solid $primary;
          }
        }
      }
    }
  }
</style>
